<template>
  <div v-if="overviewDetail !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center relative">
        <div class="cursor-pointer text-white heading-3 pl-2 font-semibold absolute left-1"  @click="$router.push({name: 'SubscriptionDetail'})"><i class="fas fa-arrow-left"></i></div>
        <h1 class="heading-2 text-center">Manage Storage</h1>
      </div>
    </div>
    <div class="flex justify-center" >
        <div class=" sm:w-full lg:w-10/12 xl:w-6/12 2xl:w-6/12">
            <div class=" text-gray3 mb-4  card rounded-xl bg-white p-4 my-4">
              <div class="flex items-center justify-between">
                <div class="text-text2 heading-4">Files Uploaded</div>
                <div class="text-center flex items-center cursor-pointer text-primary" v-if="totalSize > 0" @click="$router.push({name: 'SubscriptionStorageList'})">
                  <div class=" heading-4">{{totalSize | getFileSize}}</div>
                  <div><i class="fas fa-chevron-right pl-2"></i></div>
                </div>
                <div class="text-center flex items-center text-text2" v-if="totalSize === 0">
                  <div class="heading-4">{{totalSize | getFileSize}}</div>
                </div>
              </div>
              <div class="p-2 m-2">
                <div class="flex gap-5" style="flex-wrap: wrap;">
                  <div v-for="(attach, a) in attachmentList" :key="a" @click="showFullImage(attach, a)" class="card rounded-xl bg-white border border-gray-100">
                  <div class=" attachment-card cursor-pointer group" style="width: 150px; height: 100px">
                      <div v-if="attach.docType === 'image'" class="text-center">
                          <img style="height: 80px;width: 140px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                          <div v-if="a + 1 !== attachmentList.length" class="invisible group-hover:visible absolute bg-gray4 -bottom-14 w-full min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex heading-4">{{ attach.displayFileName }}</div>
                      </div>
                      <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                          <img style="height: 80px;width: 140px;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                          <img class="flex items-center  text-4xl absolute text-white " height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                          <div v-if="a + 1 !== attachmentList.length" class="invisible group-hover:visible w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      </div>
                      <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                          <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                          <div v-if="a + 1 !== attachmentList.length" class="invisible group-hover:visible w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      </div>
                      <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                          <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                          <div v-if="a + 1 !== attachmentList.length" class="invisible group-hover:visible w-full min-w-max -bottom-14 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      </div>
                      <div v-if="a + 1 === attachmentList.length && totalCount > 4" class="absolute  rounded-md text-black justify-center flex items-center heading-4 font-bold text-opacity-100 " style="height: 98px;width: 150px;background: #fcfcfcc7;"><span>+{{totalCount - (attachmentList.length - 1)}}</span></div>
                      <!-- <div class="absolute bottom-2 opacity-80 text-white text-center heading-6 bg-gray4 ml-1 pl-1" style="width: 142px;">{{attach.fileSize | getFileSize}}</div> -->
                    </div>
                  <div class="text-center heading-6">{{attach.fileSize | getFileSize}}</div>
                  </div>
               </div>
              </div>
              <div v-if="totalCount === 0">
                <div class="border p-1 text-center rounded border-gray2  flex items-center relative">
                  <div class="text-gray4 heading-2 pl-3">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                  </div>
                  <div class="justify-center w-full">
                    <div class="text-text2 heading-5">No Attachments found</div>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
            :defaultPerPage="100"
            :totalDataCount="totalCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getQuoteDataFromPagination"/>
        </div>
    </div>
    <FullScreen v-if="openFullScreen" :object="selectedData" :hideDelete="true"></FullScreen>
    <!-- <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" /> -->
  </div>
</template>
<script>
import FullScreen from '@/View/plan/fullScreenAtt.vue'
import Pagination from '@/View/components/pagination.vue'
import MyJobApp from "@/api/MyJobApp.js";
export default {
  name: "customer",
  components: {
    FullScreen,
    Pagination
  },
  data() {
    return {
      resetPaginationValues: false,
      totalCount: 0,
      totalSize: 0,
      pathPrefix: '',
      overviewDetail: null,
      attachmentList: [],
      activeUer: {},
      start: 0,
      length: 5,
      selectedData: null,
      openFullScreen: false,
    };
  },
  created() {},
  mounted() {
    this.$root.$on('fullImageHandler', (response) => {
      if (response) {
        this.deleteAttach()
      }
      this.openFullScreen = false
    })
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.getOverview()
    this.getlisting()
  },
  methods: {
    showFullImage (data, index) {
      if (index === 4) {
        this.$router.push({name: 'SubscriptionStorageList'})
      } else {
        this.selectedData = data
        this.openFullScreen = true
      }
    },
    getQuoteDataFromPagination (data) {
      this.start = data.offset
      this.length = data.limit
      this.getlisting()
    },
    getOverview () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetAttachmentOverview(
        response => {
          this.overviewDetail = response.Data !== null ? response.Data : null
          this.totalSize = response.Data.topSize
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getlisting () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetAttachmentList(
        this.start,
        this.length,
        response => {
          this.totalCount = response.Data.count
          this.pathPrefix = response.Data.pathPrefix
          let temp = response.Data.attachmentList !== null ? response.Data.attachmentList : []
          let templist = temp
          for (let index = 0; index < templist.length; index++) {
            console.log('templist', templist)
            let temp = templist[index].fileType.split('/')
            if (temp[0] === 'image' ) {
              templist[index].docType = 'image'
            } else if (temp[0] === 'application' && temp[1] === 'pdf') {
              templist[index].docType = 'pdf'
            } else if (temp[0] === 'video' ) {
              templist[index].docType = 'video'
            }
          }
          this.attachmentList = templist
          this.resetPaginationValues = false
          console.log('attachmentList', this.attachmentList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  },
  beforeDestroy() {
    this.$root.$off("fullImageHandler");
  },
};
</script>
<style scoped>
.attachment-card {
  display: flex;
  align-items: center;
  position: relative;
}
.scroll-img-view{
  height: 104px;
}
/* .note_icn {
  visibility:hidden;
}
.note_icn_box:hover .note_icn {
  visibility: visible !important;
} */
.zIndex {
  z-index: 100 !important;
}
</style>